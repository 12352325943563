h1 {
    @apply text-black
}

h2 {
    @apply text-black
}

h3 {
    @apply text-black
}

p {
    @apply text-black
}

ul {
    @apply text-black text-lg
}

code {
    @apply text-black font-sans
}

.pageWrapper {
    @apply flex flex-col min-h-[100vh] lg:h-screen bg-no-repeat bg-cover bg-bottom lg:bg-center
}

.contentWrapper {
    @apply flex justify-center w-screen h-full mt-2
}

.contentWrapperInner {
    @apply w-11/12 2xl:w-9/12 pt-5 2xl:pt-2
}

.formLabel {
    @apply font-bold
}

.formControlLabel {

}

.customLink {
     @apply underline text-primary hover:text-warning visited:text-warning
}

.waitImage {
    @apply max-w-[100%] w-[60%] md:w-[50%] lg:w-auto
}

.startButton {
    @apply bg-[#254375] text-white hover:bg-[#3660a6]
}

.teamButton {
    @apply bg-[#721c7a] text-white hover:bg-[#a52cb0]
}

.companyButton {
    @apply bg-primary text-white hover:bg-[#254375]
}

/* Crossroads page */
.crossroadsSection {
    @apply mb-3 2xl:mb-5 md:mb-0 flex justify-center items-center h-[150px] w-[200px] xs:h-[170px] xs:w-[210px] md:h-[250px] md:w-[300px] rounded-lg cursor-pointer
}

.crossroadsHeader {
    @apply p-10 text-center text-white text-lg xs:text-xl md:text-3xl
}

/* Journey page */
.journeyPoint {
    @apply transform w-[50px] lg:w-full 1.5lg:w-[60px] 2xl:w-full hover:w-[120%]
}

/* Box page */
.boxImage {
    @apply cursor-pointer max-w-[100%] w-[80%] xs:w-[80%] md:w-[65%] lg:w-[55%] 1.5lg:w-[70%] xl:w-[90%] 1.5xl:w-[100%] 2xl:w-[706px] 2xl:h-[532px]
}

/* Outfit page */
.outfitImage {
    @apply cursor-pointer w-[100%] h-full
}

/* Map page */
.transitIcon {
    @apply cursor-pointer w-[8vh] h-[8vh] xs:w-[9vh] xs:h-[9vh]  md:w-[10vh] md:h-[10vh] xl:w-[12vh] xl:h-[12vh] 1.5xl:w-[13vh] 1.5xl:h-[13vh] 2xl:w-[15vh] 2xl:h-[15vh]
}

/* Street view page */
.sCharacterIcon {
    @apply h-[13vh] md:h-[13vh] lg:h-[15vh] 1.5lg:h-[15vh] 1.5xl:h-[17vh] 2xl:h-[17vh]
}

.sPlaceIcon {
    @apply h-[12vh] md:h-[12vh] lg:h-[10vh] 1.5xl:h-[17vh] 2xl:h-[10vh]
}

.sMarkIcon {
    @apply w-[8vh] h-[8vh] md:w-[8vh] md:h-[8vh] 1.5xl:w-[8vh] 1.5xl:h-[8vh] 2xl:w-[9vh] 2xl:h-[9vh]
}

.sCircleIcon {
    @apply w-[4vh] h-[4vh] lg:w-[5vh] lg:h-[5vh] 1.5lg:w-[7vh] 1.5lg:h-[7vh] 1.5xl:w-[7vh] 1.5xl:h-[7vh] 2xl:w-[6vh] 2xl:h-[6vh] mr-5 2xl:mr-10
}

.sPlaceText {
   @apply text-sm md:text-lg 1.5lg:text-xl 1.5xl:text-2xl 2xl:text-3xl
}

.sPlaceImage {
    @apply max-w-full /*max-h-[60vh] xs:max-h-[60vh] md:max-h-[60vh]*/ lg:max-h-[50vh] 1.5lg:max-h-[50vh] 1.5xl:max-h-[60vh] 2xl:max-h-[65vh]
}

/* Workplace page */
.tickIcon {
    @apply w-[5vh] md:w-[6vh] lg:w-[8vh] 1.5lg:w-[8vh] 2xl:w-20 pr-2 lg:pr-5
}

.wText {
    @apply m-0 my-1 text-sm md:text-base 1.5lg:text-lg 2xl:text-xl
}

.wWrapper {
    @apply flex items-center mb-1 md:mb-2 1.5lg:mb-3 xl:mb-4 1.5xl:mb-6 2xl:mb-6
}

/* VR page */
.vrImage {
    @apply max-w-full lg:h-[45vh] 1.5lg:h-[50vh] 1.5xl:h-[55vh] 2xl:h-[55vh]
}

.vHead {
    @apply text-center lg:text-left text-lg md:text-xl lg:text-xl 1.5xl:text-3xl 2xl:text-4xl
}

.vText {
    @apply text-center lg:text-left mt-2 mt-0 text-base md:text-lg lg:text-lg 1.5xl:text-2xl 2xl:text-3xl
}

/* Offices page */
.officeImage {
    @apply max-w-full lg:h-[45vh] 1.5lg:h-[50vh] 1.5xl:h-[55vh] 2xl:h-[55vh]
}

.officeText {
    @apply text-center lg:text-left mt-0 text-base md:text-lg lg:text-lg 1.5xl:text-xl 2xl:text-xl
}

/* Equipment page */
.equipmentWrapper {
    @apply flex flex-wrap mt-5
}

.laptopWrapper {
    @apply relative
}

.laptopInfo {
    @apply absolute top-4 right-6 lg:top-1 1.5xl:top-2 1.5xl:right-7 2xl:top-3 2xl:right-8
}

.equipmentButton {
    @apply mr-1 lg:mr-10
}

.equipmentIconLarge {
    @apply cursor-pointer mr-5 mt-3 lg:mt-0 w-[30vh] h-[30vh] xs:w-[20vh] xs:h-[20vh] md:w-[20vh] md:h-[20vh]
    lg:w-[15vh] lg:h-[15vh] 1.5lg:w-[20vh] 1.5lg:h-[20vh] xl:w-[20vh] xl:h-[20vh] 1.5xl:w-[28vh] 1.5xl:h-[28vh] 2xl:w-[25vh] 2xl:h-[25vh]
}

.equipmentIconSmall {
    @apply cursor-pointer mr-5 mb-2 lg:mb-0 w-[90%] lg:w-[55%] 1.5lg:w-[50%] xl:w-[70%] 1.5xl:w-[70%] 2xl:w-[80%]
}

/* Puzzle page */
.puzzleCharacter {
    @apply ml-5 lg:ml-0 h-[30vh] lg:h-[30vh] 1.5lg:h-[35vh] xl:h-[40vh] 1.5xl:h-[45vh] 2xl:h-[35vh] max-w-[100%]
}

.backgroundImage {
    @apply mb-5 md:mb-0 max-w-[100%] lg:h-[14vh] 1.5lg:h-[18vh] xl:h-[22vh]  2xl:h-[25vh]
}

/* Eat page */
.navigationIcon {
    @apply absolute top-1/2 w-12 h-12 md:w-14 md:h-14 lg:w-14 lg:h-14 1.5lg:w-16 1.5lg:h-16 cursor-pointer
}

.eWrapper {
    @apply max-w-[100%] w-[100%] lg:w-[45vw] lg:h-[50vh] 1.5lg:w-[40vw] xl:w-[50vw] xl:h-[60vh] 1.5xl:w-[45vw] 2xl:h-[60vh] 2xl:w-[45vw]
}

.eHeadMain {
    @apply text-xl xs:text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl
}

.eHead {
    @apply m-0 text-lg  xs:text-xl xl:text-2xl 2xl:text-3xl
}

.eText {
    @apply my-1 xs:my-2 md:my-3 text-sm 1.5lg:text-base 2xl:text-lg
}
/* Ticket page */
.ticketImage {
    @apply w-[80%] md:w-[60%] lg:w-[65%] 1.5lg:w-[65%] xl:w-[85%] 1.5xl:w-[80%] 2xl:w-[95%] rounded-2xl
}

/* InfoSession page */
.selectedTopicImage {
    @apply mb-5 lg:mb-0 1.5lg:mb-5 w-[100%] lg:h-auto lg:w-[70%] xl:w-[80%] 1.5xl:w-[75%] 2xl:w-[100%]
}

.selectedTopicHead {
    @apply m-0 my-1 xl:my-2 text-xl md:text-2xl xl:text-3xl 2xl:text-4xl
}

.selectedTopicText {
    @apply m-0 text-base md:text-lg lg:text-sm xl:text-base 2xl:text-lg 3xl:text-2xl
}

.miniTopicImage {
    @apply w-[100%] lg:h-auto lg:w-[40%] 1.5lg:w-[35%] xl:w-[45%] 1.5xl:w-[40%] 2xl:w-[50%] 3xl:w-[40%]
}

.miniTopicWrapper {
    @apply grid grid-cols-3 lg:flex m-1 xs:mb-2 md:mb-5 lg:mb-10 cursor-pointer items-center
}

.miniTopicTextWrapper {
    @apply ml-5 lg:ml-10 col-span-2
}

/* Ideas page 1,2 */
.ideasImage {
    @apply max-w-[100%] lg:h-[28vh] 1.5lg:h-[32vh] 1.5xl:h-[35vh] 2xl:h-[35vh]
}

.ideasHead {
    @apply m-0 mb-5 lg:mb-2 xl:mb-5 text-xl md:text-3xl lg:text-2xl 1.5xl:text-4xl 2xl:text-5xl 3xl:text-7xl
}

.ideasText {
    @apply text-lg md:text-2xl lg:text-base 1.5lg:text-lg 1.5xl:text-2xl 2xl:text-2xl 3xl:text-4xl
}

.ideasWhyImage {
    @apply mb-5 w-[100%] max-w-[100%] lg:mb-0 lg:w-auto lg:h-[60vh] 1.5lg:h-[65vh] 1.5xl:h-[70vh] 2xl:h-[65vh] 3xl:h-[70vh]
}

.ideasWhyText {
    @apply my-1 lg:my-1 1.5lg:my-3 text-sm md:text-xl lg:text-lg 1.5xl:text-2xl 2xl:text-2xl 3xl:text-3xl
}

/* Entertainment page */
.eGridImage {
    @apply cursor-pointer max-w-[100%] md:w-[60%] lg:w-[100%] 2xl:w-full
}

.entertainmentImage {
    @apply max-w-[100%] lg:h-[50vh] 1.5lg:h-[60vh] 1.5xl:h-[65vh] 2xl:h-[65vh]
}

.entertainmentTopicWrapper {
    @apply flex flex-col lg:grid lg:grid-cols-3 lg:gap-2 justify-items-center place-items-center
}

.entertainmentHead {
    @apply text-2xl 1.5xl:text-4xl 2xl:text-5xl
}

.entertainmentText {
    @apply text-lg md:text-xl lg:text-lg 1.5xl:text-2xl 2xl:text-2xl
}

/* Benefits pages */
.benefitsImage {
    @apply max-w-[100%] /*lg:h-[50vh] 1.5lg:h-[60vh] 1.5xl:h-[65vh] 2xl:h-[65vh]*/
}

.benefitsWrapper {
    @apply grid gap-4 lg:gap-2 justify-items-center place-items-center
}

/* Company values page */
.valuesWrapper {
    @apply grid gap-4 lg:gap-7 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 justify-items-center place-items-center
}

/* Quiz page */
.correctAnswer {
    @apply border-green-500 border-4 border-solid rounded-2xl
}

.wrongAnswer {
    @apply border-red-500 border-4 border-solid rounded-2xl
}

.teamText {
    @apply text-white
}

/* Learning page */
.learningImageWrapper {
    @apply flex justify-center
}

.learningImage {
    @apply max-w-[80%] rounded-2xl h-80 cursor-pointer
}

.learningImageMobile {
    @apply max-w-[80%] md:max-w-[80%] rounded-2xl cursor-pointer
}

.learningText {
    @apply text-center
}

/* Useful links */
.linksImage {
    @apply w-full mb-5 h-[20vh] rounded-2xl
}

.linksImageMobile {
    @apply max-w-full mb-5
}

.urlImage {
    @apply max-w-full h-5 mr-2
}

.urlImageMobile {
    @apply max-w-full h-5 mr-2
}

/* Breakfast */
.breakfastImage {
    @apply max-w-[100%] lg:h-[25vh] 1.5lg:h-[30vh] 1.5xl:h-[30vh] 2xl:h-[30vh]
}

.breakfastHead {
    @apply text-2xl 1.5xl:text-4xl 2xl:text-5xl
}

.breakfastText {
    @apply text-lg md:text-xl lg:text-base 1.5xl:text-2xl 2xl:text-2xl
}