/*@tailwind base;*/
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #2870ED;
  --secondary-color: #fff;
  --button-color: #FF6130;
  --old-primary-color: #00497b;
  --welcome-color: #02a3a4;
  --team-color: #721c7a;
  --start-color: #254375;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.map-container {
  height: 100vh;
  width: 100%
}

Link {
  text-decoration: none !important;
}

.arrowBox {
  width: 200%;
  height: 30%;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  bottom: 130%;
  right: -60%;
  padding: 10px;
  font-size: 1vw;
  line-height: 22px;
  color: #00497b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrowBox:after {
  content: ' ';
  width: 0;
  height: 0;
  border-top: 1.3vw solid white;
  border-left: 1.3vw solid transparent;
  border-bottom: 1.3vw solid transparent;
  border-right: 1.3vw solid transparent;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -10%;
}

.grayImage {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.color-palette {
  margin-bottom: 1rem;
}

.color-swatch {
  height: 50px;
  width: 50px;
  border-radius: 7px;
  margin: 9px;
  cursor: pointer;
  border: 4px solid black;
}

.color-swatch-active {
  box-shadow: inset 0 0 15px #000000;
  transition: all 0.2s;
}

.jigsaw-puzzle {

  width: 100%;
  border: 2px solid black;
  margin: auto;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.25);
}

.selectedEquipment {
  border: 2px solid goldenrod;
  border-radius: 1.5rem;
}

.characterMini:active {
    border: 2px solid #ff7900;
    border-radius: 1rem;
}